import merge from "lodash/merge";
import isPlainObject from "lodash/isPlainObject";
import { makeStyles } from "@material-ui/core/styles";

import { pseudoClasses, stylesMap } from "../../shared/theme/styles";
import { config } from "../config";

export const recursivelyApplyImportantToProperty = (property, styles) =>
  Object.keys(styles).reduce(
    (result, key) => {
      const value = styles[key];

      if (isPlainObject(value)) {
        return {
          ...result,
          [key]: recursivelyApplyImportantToProperty(property, value),
        };
      }

      if (key === property) {
        return {
          ...result,
          [key]: [[].concat(value), "!important"],
        };
      }

      return result;
    },
    { ...styles },
  );

const buildComponentOverrides = (styles, overrides = {}) =>
  Object.keys(styles).reduce((mapped, key) => {
    const keyStyles = merge({}, styles[key], overrides[key]);

    if (key.startsWith("@media")) {
      mapped[key] = buildComponentOverrides(keyStyles);
    } else if (pseudoClasses.includes(key) || key.startsWith("@")) {
      mapped[key] = keyStyles;
    } else {
      mapped[key] = {
        ...keyStyles,
        [`.${config.baseClassName} &`]: keyStyles,
      };
    }
    return mapped;
  }, {});

export const buildOverrides = muiTheme => {
  const { overrides } = muiTheme;

  return Object.keys({ ...overrides, ...stylesMap }).reduce(
    (mapped, component) => {
      const stylesFn = stylesMap[component];
      const styles =
        typeof stylesFn === "function" ? stylesFn(muiTheme) : stylesFn;

      if (styles) {
        mapped[component] = buildComponentOverrides(
          styles,
          overrides[component],
        );
      } else {
        console.warn(
          `[NO_STYLES_FOUND] Need to import styles for ${component}`,
        );
        mapped[component] = overrides[component];
      }

      return mapped;
    },
    {},
  );
};

export const makeRootStyles = (stylesFn, opts) => {
  if (typeof stylesFn === "function") {
    return makeStyles(
      theme =>
        recursivelyApplyImportantToProperty(
          "boxSizing",
          buildComponentOverrides(stylesFn(theme)),
        ),
      opts,
    );
  }

  return makeStyles(
    recursivelyApplyImportantToProperty(
      "boxSizing",
      buildComponentOverrides(stylesFn),
    ),
    opts,
  );
};
