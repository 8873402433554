import React, { useContext, createContext, useRef } from "react";
import { makeRootStyles } from "../theme/styles";

const BackdropContainerContext = createContext();

const useStyles = makeRootStyles(
  () => ({
    root: {
      position: "absolute",
      left: 0,
      top: 0,
    },
    container: {
      position: "relative",
    },
  }),
  { name: "N0BackdropContainerProvider" },
);

export const BackdropContainerProvider = ({ children }) => {
  const classes = useStyles();
  const containerRef = useRef();

  return (
    <>
      <BackdropContainerContext.Provider value={containerRef}>
        {children}
      </BackdropContainerContext.Provider>
      <div className={classes.root}>
        <div className={classes.container} ref={containerRef} />
      </div>
    </>
  );
};

export const useBackdropContainer = () => {
  const context = useContext(BackdropContainerContext);
  if (!context) throw new Error("backdrop is not defined");
  return context;
};

export const withBackdropContainerProvider = Comp => {
  const HOC = props => (
    <BackdropContainerProvider>
      <Comp {...props} />
    </BackdropContainerProvider>
  );
  HOC.displayName = `WithBackdropContainerProvider(${Comp.displayName ??
    Comp.name})`;
  return HOC;
};

export default BackdropContainerContext;
