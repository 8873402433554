import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";
import { buildSansFontFamily } from "../../shared/modules/fonts";

const muiTheme = createMuiTheme();

export const palette = {
  narvarBlue: "#0096d4",
  navy: "#0e1260",
  green: "#43a97a",
  red: "#da4a33",
  frost: "#e5f5fb",
  cloud: "#dadcea",
  pistachio: "#e1efe7",
  coconut: "#fde8de",
};

export const common = {
  black: "#000000",
  charcoal: "#2C3239",
  ash: "#666666",
  ashGrey: "#B5B5B5",
  stone: "#999999",
  coin: "#ADADC9",
  steal: "#cccccc",
  heather: "#f2f2f2",
  fog: "#f9f9f9",
  white: "#ffffff",
};

export const misc = {
  alert: "#f90000",
  warning: "#ffcb12",
  success: "#00d164",
  info: "#0096d4",
  error: "#E63935",
};

export const createBaseTheme = branding => {
  const fontSize = branding.base_body_text_size;

  const borderRadius =
    branding.border_radius < 24 ? branding.border_radius : 24;
  const brandPrimary = `#${branding.brand_primary || "000000"}`;
  const brandSecondary = `#${branding.brand_secondary || "000000"}`;
  const bodyText = `#${branding.body_text || "000000"}`;
  const bodyBackground = `#${branding.body_background || "FFFFFF"}`;
  const errorColor = `#${branding.error_color || "E63935"}`;
  const errorTextColor = `#${branding.error_text_color || "FFFFFF"}`;
  const fontFamily = buildSansFontFamily(branding.primary_font || "Oswald");
  const secondaryFontFamily = buildSansFontFamily(
    branding.secondary_font || "Lato",
  );

  return createMuiTheme({
    palette: {
      background: {
        default: bodyBackground,
      },
      primary: {
        main: brandPrimary,
      },
      secondary: {
        main: brandSecondary,
      },
      text: {
        primary: bodyText,
      },
      error: {
        main: errorColor,
        text: errorTextColor,
      },
    },
    props: {
      MuiAppBar: {
        elevation: 0,
      },
      MuiButton: {
        disableRipple: true,
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButtonGroup: {
        disableRipple: true,
      },
      MuiIconButton: {
        disableRipple: true,
      },
      MuiLink: {
        underline: branding.is_link_underlined ? "always" : "none",
      },
      MuiPaper: {
        elevation: 0,
      },
      MuiSwitch: {
        disableRipple: true,
      },
      MuiTypography: {
        color: "textPrimary",
      },
      MuiOutlinedInput: {
        notched: false,
      },
    },
    shape: {
      borderRadius,
    },
    typography: {
      fontFamily,
      fontSize,
      secondaryFontFamily,
      isLinkAllCaps: branding.is_link_all_caps,
      body1: {
        fontFamily: secondaryFontFamily,
        fontSize: fontSize, // 14
        lineHeight: 1.715, // 24
        letterSpacing: "normal",
      },
      body2: {
        fontFamily: secondaryFontFamily,
        fontSize: fontSize * 1.14, // 16
        lineHeight: 1.5, // 24
        letterSpacing: "normal",
      },
      caption: {
        fontFamily: secondaryFontFamily,
        fontSize: fontSize * 0.857, // 12
        lineHeight: 1.33, // 16
      },
      h1: {
        fontSize: fontSize * 2.285, // 32
        fontWeight: 600,
        lineHeight: 1.25, // 40
        letterSpacing: "normal",
      },
      h2: {
        fontSize: fontSize * 1.715, // 24
        fontWeight: 600,
        lineHeight: 1.33, // 32
        letterSpacing: "normal",
      },
      h3: {
        fontSize: fontSize * 1.285, // 18
        fontWeight: 600,
        lineHeight: 1.33, // 24
        letterSpacing: "normal",
      },
      h4: {
        fontSize: fontSize * 1.14, // 16
        fontWeight: 600,
        lineHeight: 1.5, // 24
        letterSpacing: "normal",
      },
      h5: {
        fontFamily: secondaryFontFamily,
        fontSize: fontSize * 1,
        fontWeight: 600,
        lineHeight: 1.5, // 24
        letterSpacing: "normal",
      },
      h6: {
        fontFamily: secondaryFontFamily,
        fontSize: fontSize * 0.75,
        fontWeight: 600,
        lineHeight: 1.5, // 24
        letterSpacing: "normal",
      },
    },
  });
};

const buildToolbarHeightForProp = (cssProp = "minHeight", negative) => ({
  [cssProp]: negative ? -64 : 64,
  [`${muiTheme.breakpoints.up("xs")} and (orientation: landscape)`]: {
    [cssProp]: negative ? -48 : 48,
  },
  [muiTheme.breakpoints.up("sm")]: {
    [cssProp]: negative ? -80 : 80,
  },
});

const createTheme = (baseTheme, overrides) => {
  const paddingRadius =
    baseTheme.shape.borderRadius > 8 ? baseTheme.shape.borderRadius : 8;

  return createMuiTheme(
    merge(
      {},
      baseTheme,
      {
        mixins: {
          toolbar: buildToolbarHeightForProp("minHeight"),
          toolbarHeightForProp: buildToolbarHeightForProp,
        },
        overrides: {
          MuiButton: {
            root: {
              padding: `${muiTheme.spacing(1)}px ${muiTheme.spacing(2)}px`,
            },
            contained: {
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                "@media (hover: none)": {
                  boxShadow: "none",
                },
              },
              "&$focusVisible": {
                boxShadow: "none",
              },
              "&:active": {
                boxShadow: "none",
              },
              "&$disabled": {
                boxShadow: "none",
              },
            },
            containedSecondary: {
              color: baseTheme.palette.common.white,
            },
            containedPrimary: {
              color: baseTheme.palette.common.white,
            },
            textPrimary: {
              color: `${baseTheme.palette.primary.main}`,
              textTransform: "none",
              fontSize: "0.875rem",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            },
            outlinedPrimary: {
              border: `1px solid ${baseTheme.palette.primary.main}`,
              transition: "none",
              "&:hover": {
                border: `1px solid ${baseTheme.palette.primary.main}`,
                backgroundColor: "transparent",
              },
            },
          },
          MuiExpansionPanel: {
            root: {
              "&$expanded": {
                margin: 0,
              },
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              minHeight: "auto",
              "&$expanded": {
                minHeight: "auto",
              },
            },
            content: {
              "&$expanded": {
                margin: 0,
              },
            },
          },
          MuiInputBase: {
            input: {
              height: muiTheme.spacing(3),
            },
          },
          MuiInputLabel: {
            outlined: {
              lineHeight: `${muiTheme.spacing(2)}px`,
              transform: `translate(${paddingRadius}px, ${muiTheme.spacing(
                2,
              )}px) scale(1)`,
              "&$shrink": {
                transform: `translate(${paddingRadius}px, ${muiTheme.spacing(
                  1,
                )}px) scale(0.8)`,
              },
            },
          },
          MuiLink: {
            root: {
              cursor: "pointer",
              textTransform: baseTheme.typography.isLinkAllCaps
                ? "uppercase"
                : "none",
            },
          },
          MuiMenuItem: {
            root: {
              fontSize: baseTheme.typography.fontSize,
              lineHeight: 1.715,
              fontWeight: "bold",
            },
          },
          MuiOutlinedInput: {
            input: {
              fontSize: baseTheme.typography.fontSize,
              minHeight: "auto",
              height: muiTheme.spacing(3),
              padding: `22px ${paddingRadius}px 2px`,
            },
            inputMultiline: {
              height: "auto",
            },
          },
          MuiSnackbarContent: {
            root: {
              flexWrap: "nowrap",
              alignItems: "flex-start",
            },
          },
        },
      },
      overrides,
    ),
  );
};

export default createTheme;
