import { get, getOr, compose, curry, find } from "lodash/fp";

export const getGraphQLErrors = error => error?.graphQLErrors ?? [];
export const getGraphQLError = error => getGraphQLErrors(error)?.[0];
export const getNetworkError = error => error?.networkError;
export const getErrorBy = curry((predicate, error) =>
  find(predicate, getGraphQLErrors(error)),
);
export const getError = error =>
  getGraphQLError(error) ?? getNetworkError(error);

export const getSchemaError = getErrorBy(
  compose(
    code =>
      [
        "undefinedField",
        "missingRequiredArguments",
        "selectionMismatch",
        "variableNotUsed",
      ].indexOf(code) >= 0,
    get("extensions.code"),
  ),
);

// error that usually auto generated, eg. network error and graphql schema error
export const getUnhandledError = error =>
  getNetworkError(error) ?? getSchemaError(error);

// error that throw by backend intentional, eg. address error
export const getHandledError = error =>
  getUnhandledError(error) ? undefined : getGraphQLError(error);

// get handled error message and mask all unhandled errors (network error and
// schema error)
export const getHandledErrorMessage = (error, defaultErrorMessage) =>
  getError(error)
    ? getHandledError(error)?.message ?? defaultErrorMessage
    : undefined;

export const getAddressError = error => {
  const err = getErrorBy({ extensions: { error_type: "address" } }, error);
  return err?.extensions ?? null;
};

export const getIneligibleReturnMethodError = getErrorBy({
  extensions: { error_code: "INELIGIBLE_RETURN_METHOD" },
});

export const getIneligibleItemsReturnedError = getErrorBy({
  extensions: { error_code: "INELIGIBLE_ITEMS_RETURNED" },
});

export const getPaymentNewClientSecretError = getErrorBy(
  err => !!err?.extensions?.new_client_secret,
);

export const getPaymentNewClientSecret = compose(
  getOr(null, "extensions.new_client_secret"),
  getPaymentNewClientSecretError,
);

export const getReshopError = getErrorBy({
  extensions: { error_type: "reshop_refund_failed" },
});

export const getReshopCancelError = getErrorBy({
  error_code: "reshop_refund_cancellation_error",
});

// @link /doc/customer_service/z_error_codes.md
export const containsZErrorCode = errorMessage =>
  /^Z\d{2,}\s*:/.test(errorMessage);
