import React from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import { makeRootStyles } from "../theme/styles";

const useStyles = makeRootStyles(
  theme => ({
    errorSnackbar: {
      color: theme.palette.error.text,
      backgroundColor: theme.palette.error.main,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
  { name: "NV0ErrorSnackbar" },
);

const ErrorSnackbar = ({ message, ...props }) => {
  const classes = useStyles();

  return (
    <SnackbarContent
      aria-describedby="client-snackbar"
      className={classes.errorSnackbar}
      elevation={0}
      message={message}
      {...props}
    />
  );
};

export default ErrorSnackbar;
