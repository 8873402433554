import get from "lodash/get";

export const isEmbedIframe = window !== window.parent;

// TODO: deprecate.
// export const getPositionFromDocument = elem => {
//   // crossbrowser version
//   const box = elem.getBoundingClientRect();

//   const { body, docEl, pageXOffset, pageYOffset } = parentWindow(w => ({
//     body: w.document.body,
//     docEl: w.document.documentElement,
//     pageXOffset: w.pageXOffset,
//     pageYOffset: w.pageYOffset,
//   }));

//   const scrollTop = pageYOffset || docEl.scrollTop || body.scrollTop;
//   const scrollLeft = pageXOffset || docEl.scrollLeft || body.scrollLeft;

//   const clientTop = docEl.clientTop || body.clientTop || 0;
//   const clientLeft = docEl.clientLeft || body.clientLeft || 0;

//   const top = box.top + scrollTop - clientTop;
//   const left = box.left + scrollLeft - clientLeft;

//   return { top: Math.round(top), left: Math.round(left) };
// };

// export const getWindowFramePosition = () => {
//   console.warn('window.frameElement', window.frameElement)
//   if (window.frameElement) {
//     return getPositionFromDocument(window.frameElement);
//   }
//   return { top: 0, left: 0 };
// };

export const getFrameViewportHeight = () => {
  return get(window, "parentData.innerHeight");
};

export const setWindowFrameBoxSize = height => {
  if (!height) return;
  window.parent.postMessage(
    {
      type: "nvo",
      action: "setFrameHeight",
      data: height,
    },
    "*",
  );
};

export const scrollToPosition = (top = 0, left = 0, behavior = "smooth") => {
  window.parent.postMessage(
    {
      type: "nvo",
      action: "scrollTo",
      data: { top, left, behavior },
    },
    "*",
  );
};

export const disableParentScroll = () => {
  window.parent.postMessage({ type: "nvo", action: "disableScroll" }, "*");
};

export const reEnableParentScroll = () => {
  window.parent.postMessage({ type: "nvo", action: "reEnableScroll" }, "*");
};

export const redirectToShop = () => {
  window.parent.postMessage({ type: "nvo", action: "redirectToShop" }, "*");
};

export const redirectToCheckout = checkoutUrl => {
  window.parent.postMessage(
    { type: "nvo", action: "redirectToCheckout", data: checkoutUrl },
    "*",
  );
};

export const openLabel = url => {
  window.parent.postMessage(
    { type: "nvo", action: "openLabel", data: url },
    "*",
  );
};

export const cancelShopNowCheckout = () => {
  window.parent.postMessage(
    { type: "nvo", action: "cancelShopNowCheckout" },
    "*",
  );
};

export const toggleContainerClass = className => {
  window.parent.postMessage(
    { type: "nvo", action: "toggleContainerClass", data: className },
    "*",
  );
};
