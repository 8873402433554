export const systemFonts = [
  "Georgia",
  "Arial",
  "Helvetica",
  "Helvetica Bold",
  "Tahoma",
  "Geneva",
  "Lucida Console",
  "sans-serif",
  "serif",
];

export const googleFonts = [
  "Open Sans",
  "Roboto",
  "Lato",
  "Slabo",
  "Oswald",
  "Source Sans Pro",
];

export const fontPresets = [...systemFonts, ...googleFonts];
