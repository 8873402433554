import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { config } from "../config";
import { getBranding } from "../../shared/modules/config";

// https://storage.googleapis.com/website-snippets/bombas-background.png
export const buildBackgroundImageStyle = imageUrl =>
  imageUrl
    ? {
        background: `url("${imageUrl}") no-repeat center center fixed`,
        backgroundSize: "cover",
      }
    : {};

const removeCssNamespace = css => {
  if (!config.isNthUi && !config.isNthLoginUi) return css;

  // for integration with NTH components, remove the css namespace for reducing
  // the css selector specificity to avoid baseline css overriding nth's
  // components style
  const namespace = `.${config.baseClassName}`;
  const globalStyle = {};
  const wrapperStyle = {};
  for (let key in css[namespace]) {
    const value = css[namespace][key];
    if (typeof value === "object") {
      // nested/namespaced style was found, remove the namespace
      globalStyle[key.replace(/&\s*/g, "")] = value;
    } else {
      // style applied to namespace wrapper
      wrapperStyle[key] = value;
    }
  }

  return {
    ...css,
    ...globalStyle,
    [namespace]: wrapperStyle,
  };
};

const useStyles = makeStyles(
  theme => ({
    "@global": removeCssNamespace({
      body: {
        margin: 0,
      },

      [`.${config.baseClassName}`]: {
        overflow: "hidden",

        // CSS Reset: https://gist.github.com/DavidWells/18e73022e723037a50d6
        [`& div, & span, & applet, & object, & iframe,
        & h1, & h2, & h3, & h4, & h5, & h6, & p, & blockquote, & pre,
        & a, & abbr, & acronym, & address, & big, & cite, & code,
        & del, & dfn, & em, & img, & ins, & kbd, & q, & s, & samp,
        & small, & strike, & strong, & sub, & sup, & tt, & var,
        & b, & u, & i, & center,
        & dl, & dt, & dd, & ol, & ul, & li,
        & fieldset, & form, & label, & legend,
        & table, & caption, & tbody, & tfoot, & thead, & tr, & th, & td,
        & article, & aside, & canvas, & details, & embed,
        & figure, & figcaption, & footer, & header, & hgroup,
        & menu, & nav, & output, & ruby, & section, & summary,
        & time, & mark, & audio, & video`]: {
          margin: 0,
          padding: 0,
          border: 0,
          fontSize: "100%",
          font: "inherit",
          verticalAlign: "baseline",
        },

        "& em": {
          fontStyle: "italic",
        },

        "& :focus": {
          outline: 0,
        },

        [`& article, & aside, & details, & figcaption, & figure,
        & footer, & header, & hgroup, & menu, & nav, & section`]: {
          display: "block",
        },

        "& ol, & ul": {
          listStyle: "none",
        },

        "& blockquote, & q": {
          quotes: "none",
        },

        "& blockquote:before, & blockquote:after, & q:before, & q:after": {
          content: "none",
        },

        "& table": {
          borderCollapse: "collapse",
          borderSpacing: 0,
        },

        [`& input[type=search]::-webkit-search-cancel-button,
        & input[type=search]::-webkit-search-decoration,
        & input[type=search]::-webkit-search-results-button,
        & input[type=search]::-webkit-search-results-decoration`]: {
          appearance: "none",
        },

        "& input[type=search]": {
          appearance: "none",
          boxSizing: "content-box",
        },

        "& [hidden]": {
          display: "none",
        },

        fontSize: "100%",
        textSizeAdjust: "100%",

        "& a:focus": {
          outline: "thin dotted",
        },

        "& input:focus": {
          border: "0 none",
        },

        "& a:active, & a:hover": {
          outline: 0,
        },

        "& a": {
          ...theme.typography.body1,
          color: theme.palette.primary.main,
          fontFamily: theme.typography.secondaryFontFamily,
          textDecoration: "underline",
          fontWeight: 400,
        },

        "& a:not([disabled]):hover, & a:focus": {
          color: theme.palette.primary.dark,
        },

        // Pretty hacky solution to fix some styling issues in links
        [`& a.MuiButton-containedSecondary:not([disabled]):hover,
        & a.MuiButton-containedSecondary:focus`]: {
          color: theme.palette.secondary.contrastText,
        },

        [`& a.MuiButton-containedPrimary:not([disabled]):hover,
        & a.MuiButton-containedPrimary:focus`]: {
          color: theme.palette.primary.contrastText,
        },

        [`& a.MuiTypography-colorSecondary:not([disabled]):hover,
        & a.MuiTypography-colorSecondary:focus`]: {
          color: theme.palette.secondary.dark,
        },

        "& a.MuiTypography-colorPrimary:not([disabled]):hover, & a.MuiTypography-colorPrimary:focus": {
          color: theme.palette.primary.dark,
        },

        "& img": {
          border: 0,
          interpolationMode: "bicubic",
          verticalAlign: "middle",
        },

        "& fieldset": {
          border: 0,
          margin: 0,
          padding: 0,
        },

        "& button, & input, & select, & textarea": {
          fontSize: "100%",
          margin: 0,
          verticalAlign: "baseline",
        },

        "& button, & input": {
          lineHeight: "normal",
        },

        "& button, & select": {
          textTransform: "none",
        },

        [`& button,
        & input[type="button"],
        & input[type="reset"],
        & input[type="submit"]`]: {
          appearance: "button",
          cursor: "pointer",
        },

        "& button[disabled], & input[disabled]": {
          cursor: "default",
        },

        [`& input[type="checkbox"], & input[type="radio"]`]: {
          boxSizing: "border-box",
          padding: 0,
        },

        [`& input[type="search"]`]: {
          appearance: "textfield",
          boxSizing: "content-box",
        },

        [`& input[type="search"]::-webkit-search-cancel-button,
        & input[type="search"]::-webkit-search-decoration`]: {
          appearance: "none",
        },

        [`& button::-moz-focus-inner,
        & input::-moz-focus-inner`]: {
          border: 0,
          padding: 0,
        },

        "& textarea": {
          overflow: "auto",
          verticalAlign: "top",
          resize: "vertical",
        },

        // From Material UI CssBaseline
        // Usually applied to HTML
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",

        // Usually applied to body
        color: theme.palette.text.primary,
        ...theme.typography.body2,

        // Change from `box-sizing: content-box` so that `width`
        // is not affected by `padding` or `border`.
        boxSizing: "border-box",
        "& *, & *::before, & *::after": {
          boxSizing: "inherit",
        },
        "& strong, & b": {
          fontWeight: "bolder",
        },
        "& h1, & h2, & h3, & h4, & h5, & h6": {
          textTransform: "none",
          letterSpacing: "normal",
        },

        // Fix iOS automatic zoom when focusing inputs
        ...(getBranding("base_body_text_size") >= 16
          ? {}
          : {
              [`& select, & textarea, & input`]: {
                [`${theme.breakpoints.down(
                  "xs",
                )} and (-webkit-min-device-pixel-ratio: 0)`]: {
                  fontSize: "16px !important",
                },
              },
            }),
      },
    }),
  }),
  { name: "N0CssBaseline" },
);

function CssBaseline(props) {
  const { children = null } = props;
  useStyles();
  return <React.Fragment>{children}</React.Fragment>;
}
CssBaseline.propTypes = {
  children: PropTypes.node,
};
export default CssBaseline;
