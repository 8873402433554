import { systemFonts } from "../../retailer-app/constants/branding";

export const buildSansFontFamily = (...fontFamily) =>
  [
    ...fontFamily.map(family =>
      systemFonts.includes(family) ? `${family}` : `"${family}"`,
    ),
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(",");
