import { useMemo, useEffect, useState } from "react";
import throttle from "lodash/throttle";

import { buildOverrides, recursivelyApplyImportantToProperty } from "./styles";
import {
  getFrameViewportHeight,
  isEmbedIframe,
  // addParentResizeListener,
  // removeParentResizeListener,
} from "../../shared/modules/frame";
import createTheme, { createBaseTheme } from "../../shared/theme/create-theme";
import { useBackdropContainer } from "../contexts/backdropContainer";

function useTheme(branding) {
  const backdropRef = useBackdropContainer();

  const [frameViewportHeight, setFrameViewportHeight] = useState(
    getFrameViewportHeight(),
  );

  // TODO: deprecate. Height adjusts without this.
  // const onMount = () => {
  //   const handleResize = throttle((innerHeight) => {
  //     setFrameViewportHeight(innerHeight);
  //   }, 1000);

  //   addParentResizeListener(handleResize);

  //   return () => {
  //     removeParentResizeListener(handleResize)
  //   };
  // };

  // useEffect(onMount, []);

  return useMemo(() => {
    const baseTheme = createTheme(createBaseTheme(branding));
    const overrides = buildOverrides(baseTheme);
    const theme = createTheme(baseTheme, {
      overrides,
      breakpoints: {
        ie11: () =>
          "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)",
        edge: () => "@supports (-ms-ime-align:auto)",
      },
      mixins: {
        verticalHeight: percent => {
          if (isEmbedIframe) {
            return (frameViewportHeight * percent) / 100;
          }
          return `${percent}vh`;
        },
      },
      props: {
        MuiModal: {
          container: () => backdropRef.current,
        },
        MuiPopover: {
          container: () => backdropRef.current,
        },
      },
    });

    return {
      ...theme,
      overrides: recursivelyApplyImportantToProperty(
        "boxSizing",
        theme.overrides,
      ),
    };
  }, [backdropRef, branding, frameViewportHeight]);
}

export default useTheme;
